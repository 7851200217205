<template>
  <!-- Page Content  -->
  <b-container fluid>
    <b-row>
      <b-col sm="12" lg="6">
            <b-row>
              <b-col  sm="6" md="4" class="mb-3">
                <div class="d-flex align-items-center mb-3 mb-lg-0 bg-white px-2 py-3 rounded">
                  <div class="rounded-circle iq-card-icon iq-bg-info  dark-icon-light mr-3"><i class="far fa-eye"></i></div>
                  <div class="text-left">
                    <h4>{{ reviews.data.daily }}</h4>
                    <p class="mb-0">{{$t('daily-views')}}</p>
                  </div>
                </div>
              </b-col>
              <b-col sm="6" md="4" class="mb-3">
                <div class="d-flex align-items-center mb-3 mb-lg-0 bg-white px-2 py-3 rounded">
                  <div class="rounded-circle iq-card-icon iq-bg-primary  dark-icon-light mr-3"><i class="far fa-eye"></i></div>
                  <div class="text-left">
                    <h4>{{ reviews.data.weekly }}</h4>
                    <p class="mb-0">{{$t('weekly-views')}}</p>
                  </div>
                </div>
              </b-col>
              <b-col sm="6" md="4" class="mb-3">
                <div class="d-flex align-items-center mb-3 mb-lg-0 bg-white px-2 py-3 rounded">
                  <div class="rounded-circle iq-card-icon iq-bg-success dark-icon-light mr-3"><i class="far fa-eye"></i></div>
                  <div class="text-left">
                    <h4>{{ reviews.data.monthly }}</h4>
                    <p class="mb-0">{{$t('monthly-views')}}</p>
                  </div>
                </div>
              </b-col>
              <b-col sm="6" md="4" class="mb-3">
                <div class="d-flex align-items-center mb-3 mb-lg-0 bg-white px-2 py-3 rounded">
                  <div class="rounded-circle iq-card-icon iq-bg-info mr-3"><i class="far fa-thumbs-up"></i></div>
                  <div class="text-left">
                    <h4>{{ likes.data.daily }}</h4>
                    <p class="mb-0">{{$t('daily-likes')}}</p>
                  </div>
                </div>
              </b-col>
              <b-col sm="6" md="4" class="mb-3">
                <div class="d-flex align-items-center mb-3 mb-lg-0 bg-white px-2 py-3 rounded">
                  <div class="rounded-circle iq-card-icon iq-bg-primary mr-3"><i class="far fa-thumbs-up"></i></div>
                  <div class="text-left">
                    <h4>{{ likes.data.weekly }}</h4>
                    <p class="mb-0">{{$t('weekly-likes')}}</p>
                  </div>
                </div>
              </b-col>
              <b-col sm="6" md="4" class="mb-3">
                <div class="d-flex align-items-center mb-3 mb-lg-0 bg-white px-2 py-3 rounded">
                  <div class="rounded-circle iq-card-icon iq-bg-success mr-3"><i class="far fa-thumbs-up"></i></div>
                  <div class="text-left">
                    <h4>{{ likes.data.monthly }}</h4>
                    <p class="mb-0">{{$t('monthly-likes')}}</p>
                  </div>
                </div>
              </b-col>
              <b-col sm="6" md="4" class="mb-3">
                <div class="d-flex align-items-center mb-3 mb-lg-0 bg-white px-2 py-3 rounded">
                  <div class="rounded-circle iq-card-icon iq-bg-info mr-3"><i class="fas fa-clipboard-list"></i></div>
                  <div class="text-left">
                    <h4>{{ reservationsStatistic.data.daily }}</h4>
                    <p class="mb-0">{{$t('daily-res')}}</p>
                  </div>
                </div>
              </b-col>
              <b-col sm="6" md="4" class="mb-3">
                <div class="d-flex align-items-center mb-3 mb-lg-0 bg-white px-2 py-3 rounded">
                  <div class="rounded-circle iq-card-icon iq-bg-primary mr-3"><i class="fas fa-clipboard-list"></i></div>
                  <div class="text-left">
                    <h4>{{ reservationsStatistic.data.weekly }}</h4>
                    <p class="mb-0">{{$t('weekly-res')}}</p>
                  </div>
                </div>
              </b-col>
              <b-col sm="6" md="4" class="mb-3">
                <div class="d-flex align-items-center mb-3 mb-lg-0 bg-white px-2 py-3 rounded">
                  <div class="rounded-circle iq-card-icon iq-bg-success mr-3"><i class="fas fa-clipboard-list"></i></div>
                  <div class="text-left">
                    <h4>{{ reservationsStatistic.data.monthly }}</h4>
                    <p class="mb-0">{{$t('monthly-res')}}</p>
                  </div>
                </div>
              </b-col>
            </b-row>
      </b-col>
      <b-col cols="6">
        <iq-card class="">
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('comments') }}</h4>
          </template>
          <template v-slot:body>
            <b-table responsive :items="comment.data" :fields="fields" class="mb-0 table-borderless">
              <template v-slot:cell(rate)="{item}">
                <span style="color: gold" v-for="rate in item.rate" :key="rate">
                  <i class="fas fa-star"></i>
                </span>
                <span style="color: #ccc" v-for="rate in (5 - item.rate)" :key="rate">
                  <i class="fas fa-star"></i>
                </span>
              </template>
            </b-table>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  name: 'Dashboard3',
  mounted () {
    this.getHotelImg()
    this.hotelReviews()
    this.hotelReservations()
    this.hotelComment()
    this.hotelLikes()
  },
  data () {
    return {}
  },
  computed: {
    fields () {
      return [
        {
          label: this.$t('client-name'),
          key: 'client.name'
        },
        {
          label: this.$t('phone'),
          key: 'client.phone'
        },
        {
          label: this.$t('rate'),
          key: 'rate'
        },
        {
          label: this.$t('comment'),
          key: 'comment'
        }
      ]
    }
  }
}
</script>
